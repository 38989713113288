<script setup lang="ts">
const nuxtApp = useNuxtApp();
const isLoading = ref(false);

nuxtApp.hook("page:start", () => {
  isLoading.value = true;
});

nuxtApp.hook("page:finish", () => {
  isLoading.value = false;
});
</script>

<template>
  <div v-if="isLoading" class="fixed inset-0 z-3 flex items-center justify-center bg-maas-background-default/60 backdrop-blur-lg">
    <span class="spinner"></span>
  </div>
</template>